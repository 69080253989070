<template>
   <v-col cols="12">
     <div>
      <v-btn color="primary lighten-4"  @click="dialog=true" small>
        <v-icon class="pr-2" small>mdi-bank-plus</v-icon>agregar domicilio procesal o profesional
      </v-btn>
     </div>
    <v-dialog v-model="dialog" max-width="500px" transition="dialog-transition" scrollable>
      <v-card>
        <v-toolbar color="primary">
          <span class="subtitle white--text">{{titulo}}</span>
        </v-toolbar>
        <v-card-text class="mt-5">
          <v-alert type="warning" color="primary lighten-3"  class="body-2" :value="true">
            Es necesario que registre el campo dirección ingresando Municipo/Zona/Calle
          </v-alert>
          <v-form ref="formNuevaDireccion" v-model="valid" lazy-validation>
            <Form :modelo="direccion" :fields="fieldsDireccion" :edit="edit" :create="true"/>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" text @click="cancelarRegistro">cancelar</v-btn>
          <v-btn color="primary" @click="registrarDireccion" :disabled="!valid" :loading="loading">GUARDAR</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
   </v-col>
</template>
<script>
import Form from "@/common/util/crud/Form";
import Mregistro from "@/mixins/registro"
export default {
  components: { Form },
  mixins:[Mregistro],
  data() {
    return {
      titulo:'Nuevo domicilio procesal o profesional',
      valid: true,
      edit: true,
      loading:false,
      dialog: false,
      direccion: {
        tipo: 4,
        departamento: null,
        direccion: null,
        nro: null,
        telefono: null
      },
      fieldsDireccion: []
    };
  },
  mounted() {
    this.fieldsDireccion = [
      {
        label: "Tipo Direccion",
        model: "tipo",
        rules:"requiredSelect",
        disabled:true,
        classField:"required px-1",
        type: "select",
        items: [{ value: 3, text: "DOMICILIO" }, { value: 4, text: "OFICINA" }],
        class: "lg6 md6"
      },
      {
        label: "Departamento",
        model: "departamento",
        type: "autocomplete",
        class: "lg6 md6",
        rules:"requiredSelect",
        classField:"required",
        items: this.$store.state.session.parametros.departamentos
      },
      {
        label: "Direccion",
        model: "direccion",
        type: "text",
        rules:"required direccion",
        classField:"required",
        hint: "Municipio/Zona/Calle",
        persistenthint: true,
        class: "required lg12 md12"
      },
      {
        label: "Nro",
        model: "nro",
        type: "text",
         rules:"required",
        classField:"required px-1",
        class: "lg6 md6"
      },
      {
        label: "Telefono",
        model: "telefono",
        type: "text",
        rules:"required celular",
        classField:"required",
        class: "lg6 md6"
      }
    ];
  },
  methods:{
      registrarDireccion(){
          if(this.$refs.formNuevaDireccion.validate()){
            this.loading=true;
             this.$http.post(`${this.$apiUrl}abogado-ciudadano/direccion`,this.direccion).then(response=>{
               this.cancelarRegistro();
                this.$store.commit('abogado/setDirecciones',this.parseDataDireccion(response.data.data.direcciones));
             })
          }
      },
      cancelarRegistro(){
        this.loading=false;
         this.$refs.formNuevaDireccion.reset();
         
         this.dialog=false; 
         this.direccion.tipo=4;
      }
  }
};
</script>

