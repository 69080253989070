<template>
  <v-row>
    <v-col cols="12">
      <v-row>
        <Nuevo />
      </v-row>
    </v-col>
    <v-form ref="formDirecciones" v-model="valid" lazy-validation>
      <div
        v-for="(modelo,index) in modelos"
        :key="index"
        class="pl-3 ml-3 my-5"
        :class="getColorDireccion(modelo.activo)"
      >
        <Form :modelo="modelo" :fields="fieldsDireccion" :edit="edit" :create="false" />
        <v-btn color="error" @click="cancelar" :disabled="consulta" v-if="edit">cancelar</v-btn>
        <v-btn
          color="primary"
          :loading="consulta"
          :disabled="!valid"
          @click="modificar(modelo)"
          v-if="edit"
        >Modificar</v-btn>
      </div>
    </v-form>
  </v-row>
</template>
<script>
import Form from "@/common/util/crud/Form";
import Nuevo from "@/components/NuevaDireccion";

export default {
  props: {
    onlyActive:{
      type:Boolean,
      default:false
    }
  },
  components: {
    Form,
    Nuevo
  },
  data() {
    return {
      valid: true,
      dialog: false,
      direccion: this.personaDireccion,
      consulta: false,
      edit: false,
      fieldsDireccion: [],
      titulo: "Información de Contacto - Direccion(es)"
    };
  },
  mounted() {
    this.fieldsDireccion = [
      {
        label: "Tipo Dirección",
        model: "tipo",
        disabled: true,
        type: "text",
        items: [{ value: 3, text: "DOMICILIO" }, { value: 4, text: "OFICINA" }],
        class: "lg2 xs6",
        rules: "required",
        classField: "required"
      },
      {
        label: "Departamento",
        model: "departamento",
        type: "text",
        class: "lg2 xs6",
        rules: "requred",
        classField: "required"
      },
      {
        label: "Dirección",
        model: "direccion",
        type: "text",
        hint: "Ciudad/Zona/Calle",
        persistenthint: true,
        class: "lg4",
        rules: "required",
        classField: "required"
      },
      {
        label: "Nro",
        model: "nro",
        type: "text",
        class: "lg1 xs6",
        rules: "required",
        classField: "required"
      },
      {
        label: "Teléfono",
        model: "telefono",
        type: "text",
        class: "lg3 xs6",
        rules: "required",
        classField: "required"
      }
    ];
  },
  computed: {
    modelos() {
      return this.$store.getters['abogado/getDireccionesAbogado'](this.onlyActive) 
    }
  },
  methods: {
    modificar(direccion) {
      if (this.$refs.formModDireccion.validate()) {
        this.consulta = true;
        this.$http
          .put(`${this.urlBase}/${direccion.id}`, direccion)
          .then(response => {
            this.consulta = false;
            this.edit = false;
          })
          .catch(error => {
            this.consulta = false;
          });
      }
    },
    getColorDireccion(activo) {
      if (activo) {
        return "direccion-activa";
      } else {
        return "direccion-inactiva";
      }
    },
    cancelar() {
      this.edit = false;
    }
  }
};
</script>
<style scoped>
form .direccion-activa {
  border-left: 5px solid #34bfa3 !important;
}
form .direccion-inactiva {
  border-left: 5px solid #f4516c !important;
}
</style>